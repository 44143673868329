import React from 'react';
import PropTypes from 'prop-types';
//Components
import CheckboxInput from '../Partials/CheckboxInput';
import InputHeader from '../Partials/InputHeader';
//Hooks
import useErrors from '../Hooks/useErrors';
//Functions
import { formatFieldId } from '../Utilities/Helper';
//PropTypes
const proptypes = {
    field: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
    rootClass: PropTypes.string,
};

/**
 * Gravity Forms CheckboxFields Component
 * @param {PropTypes}
 * @returns
 */
const CheckboxFields = ({
    field,
    onChange,
    errors,
    rootClass = 'checkbox-field',
}) => {
    const name = 'input_' + formatFieldId(field.id);
    const fieldErrors = useErrors(errors, name);

    /**
     * Set checkbox fields with label
     * @returns
     */
    const setCheckboxFields = () => {
        return Object.keys(field.inputs).map(inputIndex => {
            const input = field.inputs[inputIndex];
            if (!input) return;

            const additionalParams = {};
            additionalParams['value'] = input.value;

            const inputName = `input_${formatFieldId(input.id)}`;

            return (
                <div
                    className={`${rootClass}-wrapper`}
                    key={`checkbox_key_${inputName}_${field.id}`}>
                    <CheckboxInput
                        name={inputName}
                        cssClass={`${rootClass}__checkbox${
                            fieldErrors.length > 0 ? ' input-error' : ''
                        }`}
                        handleOnChange={onChange}
                        placeholder={field.placeholder}
                        additionalParams={additionalParams}
                        isChecked={input.isSelected}
                    />
                    <label
                        className={'label ' + rootClass + '__label'}
                        htmlFor={inputName}>
                        {input.label}
                    </label>
                </div>
            );
        });
    };

    return (
        <div className={`field ${rootClass} ${field.cssClass}`}>
            <InputHeader
                field={field}
                fieldName={name}
                errors={fieldErrors}
                rootClass={rootClass + '-header'}
            />
            {field.inputs.length > 0 && setCheckboxFields()}
        </div>
    );
};

CheckboxFields.propTypes = proptypes;
export default CheckboxFields;
